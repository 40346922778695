.wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 24px 0;

  .tag {
    padding: 8px 16px;
    border: 1px solid var(--grey-lines-color);
    border-radius: var(--border-radius-small);
    font-size: 16px;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
  }
}