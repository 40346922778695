@import "/src/styles/mixin";

// .wrap {
//   position: relative;
//   display: flex;
//   gap: 32px;
//   max-width: 1024px;
//   min-width: calc(100svw - 140px);
//   // justify-content: space-between;

//   .map_wrap {
//     width: 100%;
//     height: 600px;
//   }
// }

// .marker_wrap:hover {
//   .custom_balloon {
//     z-index: 7802;
//     opacity: 1;
//   }
// }

// staroe
// .wrap {
//   position: relative;
//   display: flex;
//   gap: 70px;
//   max-width: 1024px;
//   min-width: calc(100svw - 140px);
//   justify-content: space-between;

//   .map_wrap {
//     width: 100%;
//     height: 600px;
//   }

//   // staroe
//   @media only screen and (max-width: 1023.98px) {
//     gap: 32px;
//   }

//   @media only screen and (max-width: 768px) {
//     flex-direction: column;
//     gap: 12px;
//     min-width: calc(100svw - 110px);
//   }

//   @media only screen and (max-width: 425px) {
//     min-width: calc(100svw - 50px);
//   }

//   .left {
//     padding-right: 8px;
//     height: 568px;
//     max-height: 568px;
//     display: flex;
//     flex-direction: column;
//     gap: 24px;
//     overflow-x: hidden;
//     width: 100%;
//     max-width: 500px;
//     min-width: 300px;

//     @media only screen and (max-width: 768px) {
//       height: 70vh;
//       max-width: 100%;
//     }

//     @media only screen and (max-width: 1440px) {
//       max-width: 550px;
//     }

//     @media only screen and (max-width: 769px) {
//       max-width: 100%;
//       height: 70vh;
//       min-width: 270px;
//     }
//   }

//   .left_active {
//     display: flex;
//   }

//   .left_disable {
//     display: none;

//     @media only screen and (min-width: 769px) {
//       display: flex;
//     }
//   }

//   .map_buttons {
//     display: flex;
//     width: 100%;
//     flex-wrap: nowrap;
//     align-items: center;
//     padding: 8px 8px;
//     gap: 8px;
//     justify-content: space-between;
//     background-color: var(--blue-background-color);
//     border-radius: var(--border-radius-small);

//     .button {
//       width: 100%;
//       @include font14;
//       color: var(--black-color);
//       padding: 12px 16px;
//     }

//     .button_active {
//       width: 100%;
//       @include font14;
//       color: var(--black-color);
//       padding: 12px 16px;
//       background-color: var(--white-color);
//       border-radius: var(--border-radius-small);
//     }

//     @media screen and (min-width: 769px) {
//       display: none;
//     }
//   }

//   .right {
//     width: 100%;
//     max-height: 568px;
//     height: 568px;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 12px;

//     @media only screen and (max-width: 1440px) {
//       max-width: 550px;
//     }

//     @media only screen and (max-width: 769px) {
//       max-width: 100%;
//     }

//     @media only screen and (max-width: 768px) {
//       height: 70vh;
//     }
//   }

//   .right_active {
//     width: 100%;

//     @media only screen and (max-width: 768px) {
//       display: flex;
//     }
//   }

//   .right_disable {
//     display: none;

//     @media only screen and (min-width: 769px) {
//       display: flex;
//     }
//   }
// }

// -new staroe

// .map {
//   width: 100%;
//   min-height: 100%;
//   max-height: calc(100% - 32px);

//   @media screen and (min-width: 769px) and (max-width: 1024px) {
//     max-width: 100% !important;
//   }

//   .ymaps-2-1-79-balloon {
//     box-shadow: unset;

//     &__layout {
//       border-radius: 8px;
//     }

//     &__content {
//       font: unset;
//       padding: 20px 30px;
//     }
//   }

//   .map {
//     min-width: 100%;

//     &-header {
//       &-title {
//         @include font16Bold;
//         margin-bottom: 10px;
//       }
//     }

//     &-body {
//       &-text {
//         @include font14;

//         &.grey--text {
//           color: var(--grey-color);
//         }
//       }

//       &-link {
//         @include font14;
//         position: relative;
//         display: none;
//         transition: color 0.2s ease;

//         &::after {
//           content: "";
//           position: absolute;
//           bottom: 0;
//           left: 0;
//           height: 1px;
//           width: 100%;
//           border-bottom: 1px dashed var(--black-color);
//         }

//         &:hover {
//           color: var(--primary-color);
//         }
//       }
//     }
//   }
// }

.showMoreButton {
  @include contentButton;
  @include font14;
  padding: 6px 12px;
  margin-top: 12px;
  background-color: var(--primary-color);
}

.search {
  &Text {
    @include font14;
    margin-bottom: 8px;
    color: var(--grey-color);
  }
}

.input {
  &Wrap {
    margin-bottom: 12px;
    max-width: 370px;

    @media only screen and (max-width: 767.98px) {
      max-width: unset;
    }
  }
}

.findButton {
  @include font14;
  display: flex;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 767.98px) {
    display: none;
  }

  &Mob {
    @include font14;
    display: none;
    align-items: center;
    gap: 8px;

    @media only screen and (max-width: 767.98px) {
      display: flex;
    }
  }
}


// ------------- NEW MAP
.wrap {
  display: flex;
  gap: 12px;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;
    justify-content: space-between;

    .shops_list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 340px;
      height: 100%;

      .point_list {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow: scroll;
        max-height: 424px;
        padding-bottom: 4px;
        @media screen and (max-width: 768px) {
          justify-content: center;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .map_side {
      width: calc(100% - 372px);
      height: 100%;
      min-height: 500px;

      .map_wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        // overflow: hidden;
        border-radius: var(--border-radius-normal);

        @media screen and (max-width: 767px) {
          border-radius: var(--border-radius-small);
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .show_list {
      display: flex;
    }

    .hidden_list {
      display: none;
      @media screen and (min-width: 769px) {
        display: flex;
      }
    }

    .show_map {
      width: 100%;
      display: flex;
    }

    .hidden_map {
      display: none;
      @media screen and (min-width: 769px) {
        display: flex;
      }
    }
  }

  .tab_buttons {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 8px;
    gap: 8px;
    justify-content: space-between;
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);

    .button {
      width: 100%;
      @include font14;
      color: var(--black-color);
      padding: 12px 16px;
    }

    .button_active {
      width: 100%;
      @include font14;
      color: var(--black-color);
      padding: 12px 16px;
      background-color: var(--white-color);
      border-radius: var(--border-radius-small);
    }

    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

.isClosed {
  opacity: .5;
}


.trogal {
  display: block;
}

.hidden {
  display: none;
}