@import "/src/styles/mixin";

.columns {
  display: flex;
  align-items: flex-start;
  gap: 60px;
  @media screen and (max-width: 568px) {
    flex-direction: column;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column-gap: 36px;
    grid-row-gap: 24px;
    @media screen and (max-width: 768px) {
      gap: 12px;
    }
    .inputWrap {
      width: 100%;
    }
    .user_info{
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 35px;
      @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        gap: 12px;
      }
      .inputWrap {
        width: 50%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}