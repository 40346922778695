.wrap {
  display: flex;
  gap: 70px;
  max-width: 1100px;
  min-width: calc(100svw - 140px);
  justify-content: space-between;

  @media only screen and (max-width: 1023.98px) {
    gap: 32px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    min-width: calc(100svw - 110px);
  }

  @media only screen and (max-width: 425px) {
    min-width: calc(100svw - 50px);
  }

}

.container {
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding: 8px 0px;
  }
}