@import "/src/styles/mixin";

.form {
  margin-bottom: 70px;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    margin-bottom: 40px;
  }

  &Text {
    @include font12;
    font-weight: 400;
    color: var(--grey-color);
  }

  &Link {
    @include font12;
    font-weight: 400;
    color: var(--hover-primary-color);
  }

  &Button {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
  }

  &Input {
    @include font14;
    width: 100%;
    padding: 16px 43px 16px 16px;
    border: 1px solid var(--grey-color);
    border-radius: var(--border-radius-small);
    color: var(--white-color);
    transition: border-color 0.2s ease;

    &:focus,
    &:hover {
      border-color: var(--white-color);
    }

    &.is-not-valid {
      border-color: var(--red-color);
    }

    &Wrap {
      position: relative;
      margin-bottom: 12px;
    }
  }
}