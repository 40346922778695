@import "/src/styles/mixin";

.wrap {
  width: 450px;

  @media only screen and (max-width: 530px) {
    max-width: calc(100vw - 50px);
  }

  .form {
    width: 100%;
  }
}

.inputWrap {
  margin-bottom: 24px;
  width: 100%;
}

.button {
  padding: 14px 26px;
}