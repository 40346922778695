@import "src/styles/mixin";

.image_wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  margin-bottom: 12px;

  .caption_wrap {
    width: 50%;

    .text {
      @include font16;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .image_wrap {
    position: relative;

    .image {
      height: auto;
      object-fit: cover;
      border-radius: var(--border-radius-normal);
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

}