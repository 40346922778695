@import "src/styles/mixin";

.mark {
  position: absolute;
  width: 20px;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  background-color: var(--primary-color);
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  z-index: 1;

  .product_container {
    position: relative;
    width: 100%;
    height: 100%;

    .spacer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 30px;
      height: 30px;
      background-color: var(--primary-color);
      animation: pulse 2s ease 0s infinite normal forwards;
      opacity: 1;
      border-radius: 50%;
    }

    .product {
      position: absolute;
      left: 50%;
      top: 50%;

      display: flex;
      flex-direction: column;
      gap: 4px;

      height: 238px;
      width: 168px;
      transform: translate(-50%, -50%);

      background-color: var(--white-color);
      padding: 2px 8px 8px;
      border: 1px solid var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      z-index: 2;

      .image {
        aspect-ratio: 1/1;
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      .name {
        @include font14;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: auto;
      }

      .price_wrap {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        .price {
          @include font16Bold;
          font-weight: 600;
        }

        .button {
          @include contentButton;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          transition: .25s ease;

          &:active {
            transform: scale(0.96);
          }
        }

        .inCart {
          background-color: var(--blue-background-color);
          border: 1px solid var(--blue-background-color);

          &:active {
            transform: scale(0.96);
          }

          &:hover {
            background-color: var(--grey-lines-color);
            border: 1px solid var(--blue-background-color);
          }
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 26px;
      height: 26px;
      border: 1px solid var(--primary-color);
      border-radius: 50%;
    }
  }


}

@keyframes pulse {
  0% {
    opacity: .9;
    transform: translate(-50%, -50%) scale(0.2);
  }

  50% {
    opacity: .7;
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.8);
  }
}