.cluster {
  display: flex;
  border-radius: 50%;
  background-color: var(--white-color);
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  padding: 2px 2px;
  position: absolute;
  left: -19px;
  top: -19px;

  .value {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
    border-radius: 50%;
  }
}

.all {
  background: rgb(116, 100, 209);
  background: linear-gradient(90deg, rgba(116, 100, 209, 1) 50%, rgba(229, 79, 109, 1) 50%);
}

.llro {
  background: rgb(229, 79, 109);
  background: linear-gradient(90deg, rgba(229, 79, 109, 1) 50%, rgba(229, 79, 109, 1) 50%);
}

.clubs {
  background: rgb(116, 100, 209);
  background: linear-gradient(90deg, rgba(116, 100, 209, 1) 50%, rgba(116, 100, 209, 1) 50%);
}