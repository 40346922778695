@import "/src/styles/mixin";

.questions_wrap {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 320px;
  height: 537px;
  top: 50%;
  right: 0;
  transform: translate(-5%, -50%);
  z-index: 100;
  padding: 16px;
  background-color: var(--white-color);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--grey-lines-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  transition: all 0.6s;
  transition-delay: 1s;

  overflow: auto;

  .questions_head {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    .step {
      @include font14;
      font-weight: 600;
    }

    .img {
      width: 50px;
      height: auto;
    }
  }

  .questions_content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: 100%;
    gap: 8px;

    .title {
      margin-bottom: 4px;
    }

    .formRadio {
      padding: 17.5px 24px;
      border: 1px solid var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      cursor: pointer;
    }
  }

  .questions_footer {
    margin-top: 12px;
    width: 100%;
    display: flex;

    .button {
      @include contentButton;
      @include font14;
      font-weight: 700;
      padding: 8px 43px;
      margin-left: auto;
    }
  }

  .shadow {
    display: none;
  }

  @media screen and (min-width: 768px) {
    animation: openPopup 0.6s ease-out;
    animation-delay: 1.5s;
  }

  @media screen and (max-width: 767px) {
    transition-delay: 0s;
  }

  @media screen and (max-width: 475px) {
    right: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    transform: translate(0, -50%);
  }

  @keyframes openPopup {
    0% {
      transform: translate(-5%, -50%);
    }

    50% {
      transform: translate(-8%, -50%);
    }

    100% {
      transform: translate(-5%, -50%);
    }
  }
}

.questions_closed {
  display: flex;
  position: relative;
  flex-direction: column;
  position: fixed;
  width: 320px;
  height: 537px;
  top: 50%;
  right: -320px;
  transform: translate(0, -50%);
  z-index: 100;
  padding: 16px;
  background-color: var(--white-color);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--grey-lines-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  transition: all 0.6s;

  .questions_head {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    .step {
      @include font14;
      font-weight: 600;
    }

    .img {
      width: 130px;
      height: auto;
    }
  }

  .questions_content {
    margin-top: 28px;
    height: 100%;
  }

  .questions_footer {
    width: 100%;
    display: flex;

    .button {
      @include contentButton;
      @include font14;
      font-weight: 700;
      padding: 8px 43px;
      margin-left: auto;
    }
  }

  @media screen and (max-width: 767px) {
    right: -300px;
    animation: 4s ease 0s infinite alternate closePopup;
    animation-delay: 2s;
  }

  .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    &_button {
      position: relative;
      width: 24px;
      height: 24px;

      .icon {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }

  @keyframes closePopup {
    0% {
      transform: translate(-2%, -50%);
    }

    10% {
      transform: translate(-8%, -50%);
    }

    25% {
      transform: translate(-2%, -50%);
    }

    30% {
      transform: translate(-2%, -50%);
    }

    35% {
      transform: translate(-2%, -50%);
    }

    40% {
      transform: translate(-2%, -50%);
    }

    45% {
      transform: translate(-2%, -50%);
    }

    50% {
      transform: translate(-2%, -50%);
    }

    55% {
      transform: translate(-2%, -50%);
    }

    60% {
      transform: translate(-2%, -50%);
    }

    65% {
      transform: translate(-2%, -50%);
    }

    70% {
      transform: translate(-2%, -50%);
    }

    75% {
      transform: translate(-2%, -50%);
    }

    80% {
      transform: translate(-2%, -50%);
    }

    90% {
      transform: translate(-2%, -50%);
    }

    95% {
      transform: translate(-2%, -50%);
    }

    100% {
      transform: translate(-2%, -50%);
    }
  }
}
