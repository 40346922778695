@import "/src/styles/mixin";

.subtitle {
  @include font14;
  margin-bottom: 14px;
  text-align: center;
}

.buttonWrap {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.confirm {
  @include font14;
  padding: 16px 26px;
}

.cancel {
  @include font14;
  padding: 16px 26px;
  background: transparent;
}
