@import "src/styles/mixin";

.modal {
  z-index: 900000 !important;
  padding: 20px 20px !important;
  width: 100%;
  max-width: 680px;
  max-height: calc(100svh - 40px);

  &_container {
    .cart {
      position: relative;
      z-index: 900000;

      & > main {
        min-height: auto;
        padding-bottom: 0;

        .container {
          height: auto;

          & > section {
            padding: 0 0;

            & > p {
              @include font20;
              margin-bottom: 13px;
              position: sticky;
              top: 10px;
              background-color: var(--white-color);
              z-index: 3;
              transform: translateY(-31px);
              padding: 10px 0;
            }

            & > div {
              display: flex;
              flex-direction: column;
              transform: translateY(-58px);
              max-width: 640px;
              gap: 20px;

              & > div {
                width: 100%;
              }

              @media screen and (max-width: 767px) {
                transform: translateY(-30px);
              }
            }
          }
        }
      }

      .close {
        position: sticky;
        top: -10px;
        right: 20px;
        z-index: 4;
        margin-left: calc(100% - 20px);
        background-color: var(--white-color);
        transform: translateY(6px);
      }
    }

    @media screen and (max-width: 767px) {
      padding: 0 0 !important;
    }
  }

  & > button {
    position: absolute;
    top: -10px;
    margin-left: calc(100% - 20px);
    background-color: var(--white-color);
    z-index: 4;

    @media screen and (max-width: 767px) {
      top: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    border-radius: 0 !important;
  }
}

.shadow {
  z-index: 8999999;
  background-color: rgba(0, 0, 0, .85);
}